import React, { useState, useContext, useEffect } from 'react';
import Logo from '../../assets//img/brand/linko_name_png.png';
import AdminLogoDark from '../../assets/plugins/images/admin-logo-dark.png';
import AdminText from '../../assets/plugins/images/admin-text.png';
import AdminTextDark from '../../assets/plugins/images/admin-text-dark.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPowerOff, FaUser, FaBuilding, FaEnvelope } from 'react-icons/fa';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { AuthContext } from '../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { logout, loggedOut } = useContext(AuthContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleLogout = () => {
        // Add your logout logic here

        logout();
    };

    useEffect(() => {
        if (loggedOut) {
            navigate('/login');
        }
    }, [loggedOut]);
    return (
        <nav
            className="navbar navbar-default navbar-static-top p-0"
            style={{ width: '100vw', zIndex: 30000 }}>
            <div
                className="navbar-header d-flex flex-row justify-content-between align-items-center"
                style={{ backgroundColor: '#8ef9fc' }}>
                <div
                    className="top-left-part"
                    style={{
                        backgroundColor: 'inherit',
                        borderWidth: 0,
                    }}>
                    <a className="logo border-none" href="/data">
                        {/* <b> */}
                        <img src={Logo} alt="home" style={{ width: 200 }} />
                        {/* <img
                                src={AdminLogoDark}
                                alt="home"
                                className="light-logo"
                            /> */}
                        {/* </b> */}
                        {/* <span className="hidden-xs">
                            <img
                                src={AdminText}
                                alt="home"
                                className="dark-logo"
                            />
                            <img
                                src={AdminTextDark}
                                alt="home"
                                className="light-logo"
                            />
                        </span> */}
                    </a>
                </div>

                <ul className="nav">
                    <li>
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            className="d-inline-block">
                            <DropdownToggle
                                style={{ zIndex: 30000 }}
                                className="profile-pic text-decoration-none"
                                tag="a"
                                href="#"
                                onClick={(e) => e.preventDefault()}>
                                <img
                                    style={{
                                        width: '35px',
                                        height: 'auto',
                                    }}
                                    src={`https://ui-avatars.com/api/?name=${userInfo?.name}&background=512ca8&color=ffffff&bold=true&rounded=true`}
                                    alt="User Avatar"
                                />
                                <b className="hidden-xs text-white"></b>
                            </DropdownToggle>
                            <DropdownMenu
                                style={{
                                    width: '310px',
                                    marginTop: '15px',
                                    marginRight: '25px',
                                }}
                                end>
                                <div className="d-flex flex-row align-items-center px-2 py-2">
                                    {/* <FaUser size="14px" className="me-2" /> */}
                                    <span className="font-bold">
                                        {userInfo?.name}
                                    </span>
                                </div>
                                <div className="d-flex flex-row align-items-center px-2 text-secondary">
                                    {/* <FaEnvelope size="14px" className="me-2" /> */}
                                    <i className="font-italic">
                                        {userInfo?.email}
                                    </i>
                                </div>
                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={() => {
                                        navigate('/data');
                                    }}>
                                    Data
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        navigate('/settings');
                                    }}>
                                    Settings
                                </DropdownItem>

                                <DropdownItem onClick={handleLogout}>
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
