import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { BASE_URL } from '../../utils/config';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCopy } from 'react-icons/fa';

export default function Settings() {
    const [disabled, setDisabled] = useState(false);
    const { getCode, codeData, changePage } = useContext(AuthContext);
    const [selectedPages, setSelectedPages] = useState('');
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(`${BASE_URL}/api/add-task/${codeData?.data?.code}`)
            .then(() => {
                toast.info('Copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };
    useEffect(() => {
        getCode();
    }, []);

    useEffect(() => {
        if (codeData) {
            setDisabled(false);
            setSelectedPages(codeData?.data?.pages);
        }
    }, [codeData]);

    const handleUpdate = () => {
        setDisabled(true);
        changePage(selectedPages);
    };

    // return (
    //     <div className="fix-header">
    //         <div id="wrapper">
    //             <Header />
    //             <Sidebar />
    //             <div id="page-wrapper" style={{ height: 'calc(100vh - 60px)' }}>
    //                 <div
    //                     className="container d-flex justify-content-center align-items-center"
    //                     style={{ height: '100%' }}>
    //                     {/* <div className="row justify-content-center"> */}
    //                     <div className="col-md-6">
    //                         <div className="input-group mb-3">
    //                             <input
    //                                 type="text"
    //                                 className="form-control"
    //                                 value={`${BASE_URL}/api/add-task/${codeData?.code}`}
    //                                 readOnly
    //                             />
    //                             <div className="input-group-append">
    //                                 <button
    //                                     className="btn btn-outline-secondary"
    //                                     type="button"
    //                                     onClick={copyToClipboard}>
    //                                     Copy
    //                                 </button>
    //                             </div>
    //                         </div>

    //                         <div className="col-md-8">
    //                             <div className="input-group ">
    //                                 <label className="input-group-append">
    //                                     Pages to Scrap
    //                                 </label>
    //                                 <select
    //                                     className="form-control"
    //                                     value={selectedPages}
    //                                     onChange={(e) =>
    //                                         setSelectedPages(e.target.value)
    //                                     }>
    //                                     <option value="" disabled>
    //                                         Select number of pages to scrape
    //                                     </option>
    //                                     {[...Array(10)].map((_, i) => (
    //                                         <option key={i + 1} value={i + 1}>
    //                                             {i + 1}
    //                                         </option>
    //                                     ))}
    //                                 </select>
    //                                 <div className="input-group-append">
    //                                     <button
    //                                         className="btn btn-primary"
    //                                         type="button"
    //                                         onClick={handleUpdate}>
    //                                         Update
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     {/* </div> */}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="fix-header">
            <div id="wrapper">
                <Header />
                {/* <Sidebar /> */}
                <ToastContainer style={{ marginTop: '60px' }} />
                <div
                    id="page-wrapper12"
                    style={{ height: 'calc(100vh - 60px)' }}>
                    <div className="container" style={{ marginTop: '60px' }}>
                        <br></br>
                        <h3 className="font-bold">Settings</h3>
                        <div className="row justify-content-start">
                            <div className="col-md-6 mt-2">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Webhook URL for Chrome Extension
                                        </h5>
                                        <div className="input-group">
                                            <p
                                                className="m-0 p-0 form-control border-none text-decoration-none w-25"
                                                style={{
                                                    borderWidth: 0,
                                                    width: '50px',
                                                }}>
                                                {`${BASE_URL}/api/add-task/${codeData?.data?.code}`}{' '}
                                                <FaCopy
                                                    className="input-group-append"
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'lightblue',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={copyToClipboard}
                                                />
                                            </p>
                                        </div>
                                        <div className="input-group">
                                            <a
                                                target="_blank"
                                                href="https://chromewebstore.google.com/detail/data-linko/fcphejdbdhfglcdipihdimkjahkffbgm"
                                                className="btn btn-sm btn-outline btn-primary">
                                                Download Chrome Extension
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Credit Balance -{' '}
                                            <span className="font-bold">
                                                {codeData?.data?.credits}
                                            </span>
                                        </h5>
                                        <h5 className="card-title">
                                            Per Day Limit -{' '}
                                            <span className="font-bold">
                                                {codeData?.scrappedToday}/
                                                {codeData?.data?.per_day_limit}
                                            </span>
                                        </h5>
                                        {/* <p className="card-text">
                                            <span className="font-bold">
                                                
                                            </span>{' '}
                                            <a href="/">
                                                <small>upgrade</small>
                                            </a>
                                           
                                        </p> */}
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Number of pages to scrape from
                                            search results
                                        </h5>
                                        <div className="row">
                                            <div className="input-group col-md-2 w-50">
                                                <select
                                                    className="form-control w-25"
                                                    value={selectedPages}
                                                    onChange={(e) =>
                                                        setSelectedPages(
                                                            e.target.value
                                                        )
                                                    }>
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    {[...Array(10)].map(
                                                        (_, i) => (
                                                            <option
                                                                key={i + 1}
                                                                value={i + 1}>
                                                                {i + 1}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <div className="input-group-append">
                                                    <button
                                                        disabled={disabled}
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={handleUpdate}>
                                                        {disabled ? (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}>
                                                                <div
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    style={{
                                                                        width: '1.5rem',
                                                                        height: '1.5rem',
                                                                    }}>
                                                                    <span className="visually-hidden">
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            // </div>
                                                            <div>Update</div>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
