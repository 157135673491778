import React, { useState, useEffect, useContext } from 'react';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { AuthContext } from '../../utils/AuthContext';
import '../../assets/css/table.css';
import { data } from 'jquery';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/pagination.css';
import '../../assets/css/modal.css';
import 'bootstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle, FaLock, FaCopy } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from './LoadingOverlay';
import { FaTrash, FaEdit } from 'react-icons/fa';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
} from 'reactstrap';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';

export default function Admin() {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filteredUser, setFilteredUser] = useState(null);
    const [editShow, setEditShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [status, setStatus] = useState('1');
    const [perDayLimit, setPerDayLimit] = useState(0);
    const [creditBalance, setCreditBalance] = useState(0);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [loadingUsers, setLoadingUsers] = useState({});

    const {
        getUsers,
        usersList,
        pages,
        counts,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        editUserDetails,
        completeUserPendingData,
    } = useContext(AuthContext);

    const editToggle = () => setEditShow(!editShow);

    const handleEditToggle = (fullName, email, id, status, limit, credit) => {
        setId(id);
        if (status === true) {
            setStatus('1');
        } else {
            setStatus('2');
        }
        setFullName(fullName);
        setEmail(email);
        setPerDayLimit(limit);
        setCreditBalance(credit);
        editToggle();
    };

    const handleSaveClose = () => {
        editUserDetails(id, status, perDayLimit, creditBalance, page + 1);
    };

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        }
        if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setStatus('1');
        setPerDayLimit(0);
        setCreditBalance(0);
        setId(null);
        setEditShow(false);
    }, [toastrError, toastrMsg]);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (usersList) {
            setLoading(false);
        }
    }, [usersList]);

    const handleCompleteTask = async (userId) => {
        console.log(userId);
        // Set the loading state to true for the specific user
        setLoadingUsers((prev) => ({ ...prev, [userId]: true }));

        try {
            const response = await axios.post(
                `${BASE_URL}/api/complete/`,
                { userId },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            );
            const incomingData = response.data;
            if (incomingData.success) {
                toast.success(incomingData.msg);
            } else {
                toast.error(incomingData.msg);
            }
        } catch (error) {
            console.error('Error completing task:', error);
        } finally {
            getUsers();
            // Set the loading state to false for the specific user
            setLoadingUsers((prev) => ({ ...prev, [userId]: false }));
        }
    };

    return (
        <div className="fix-header">
            <div id="wrapper">
                <Header />
                {/* <Sidebar /> */}
                <ToastContainer style={{ marginTop: '60px' }} />

                <div
                    id="page-wrapper33"
                    style={{ height: 'calc(100vh - 60px)' }}>
                    {!loading ? (
                        <div className="row" style={{ marginTop: '60px' }}>
                            <div className="col-sm-12">
                                <div className="white-box mb-0">
                                    <h3 className="font-bold text-dark">
                                        All Users
                                    </h3>
                                    <div
                                        className="col d-flex justify-content-end align-items-center"
                                        style={{ height: '100%' }}>
                                        {counts?.c > 0 && (
                                            <div className="me-2">
                                                {counts?.a} - {counts?.b} of{' '}
                                                {counts?.c}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            maxHeight: '610px',
                                            overflow: 'scroll',
                                        }}>
                                        <table
                                            className="table table-striped table-bordered"
                                            style={{
                                                fontSize: '15px',
                                                // width: '100%',
                                                // tableLayout: 'fixed', // Ensures that the table layout is fixed
                                                // borderCollapse: 'collapse',
                                            }}>
                                            <thead
                                                className="thead-sticky table-info text-center"
                                                style={{
                                                    maxWidth: '200px',
                                                    minWidth: '200px',
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'normal',
                                                }}>
                                                <tr>
                                                    <th
                                                        style={{
                                                            maxWidth: '50px',
                                                            minWidth: '50px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        #
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '150px',
                                                            minWidth: '150px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        User
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            // width: '300px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Data Scrapped
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            // width: '300px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Scrapped Status
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '130px',
                                                            minWidth: '130px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Per Day Limit
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '130px',
                                                            minWidth: '130px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Credit Balance
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '130px',
                                                            minWidth: '130px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Status
                                                    </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {usersList &&
                                                    usersList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {page * 25 +
                                                                        (i + 1)}
                                                                </td>
                                                                <td className="text-left">
                                                                    <span>
                                                                        {
                                                                            x.first_name
                                                                        }{' '}
                                                                        {
                                                                            x.last_name
                                                                        }
                                                                        {x.is_staff && (
                                                                            <span class="badge text-bg-primary ms-3">
                                                                                Admin
                                                                            </span>
                                                                        )}
                                                                    </span>

                                                                    <br></br>
                                                                    <span
                                                                        className="text-muted "
                                                                        style={{
                                                                            fontSize:
                                                                                '12px',
                                                                        }}>
                                                                        {
                                                                            x.username
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td className="text-center">
                                                                    <div className="row">
                                                                        <div className="col col-md-6">
                                                                            {
                                                                                x.total_data_scrapped
                                                                            }
                                                                            <br></br>
                                                                            <span
                                                                                className="text-muted"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '12px',
                                                                                }}>
                                                                                Total
                                                                            </span>
                                                                        </div>
                                                                        <div className="col col-md-6">
                                                                            {
                                                                                x.data_scrapped_today
                                                                            }
                                                                            <br></br>
                                                                            <span
                                                                                className="text-muted"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '12px',
                                                                                }}>
                                                                                Today
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="text-center">
                                                                    <div className="row">
                                                                        <div className="col col-md-6">
                                                                            <span>
                                                                                {
                                                                                    x.completed_data_count
                                                                                }
                                                                            </span>

                                                                            <br></br>
                                                                            <span
                                                                                className="text-muted"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '12px',
                                                                                }}>
                                                                                Completed
                                                                            </span>
                                                                        </div>
                                                                        <div className="col col-md-6">
                                                                            {x.pending_data_count ? (
                                                                                <button
                                                                                    className="btn-xs btn-primary m-0"
                                                                                    style={{
                                                                                        width: '80px',
                                                                                        // height: '15px',
                                                                                        color: 'white',
                                                                                        fontSize:
                                                                                            '12px',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleCompleteTask(
                                                                                            x.id
                                                                                        );
                                                                                    }}>
                                                                                    {loadingUsers[
                                                                                        x
                                                                                            .id
                                                                                    ] ? (
                                                                                        <span className="spinner-border spinner-border-sm text-white"></span>
                                                                                    ) : (
                                                                                        x.pending_data_count
                                                                                    )}
                                                                                </button>
                                                                            ) : (
                                                                                <span>
                                                                                    {
                                                                                        x.pending_data_count
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            <br></br>
                                                                            <span
                                                                                className="text-muted m-0"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '12px',
                                                                                }}>
                                                                                Pending
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="text-center">
                                                                    {
                                                                        x.per_day_limit
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        x.credit_balance
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {x.active
                                                                        ? 'Active'
                                                                        : 'Inactive'}
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="d-flex flex-row align-items-center justify-content-end"></div>
                                                                    <FaEdit
                                                                        className="input-group-append me-2"
                                                                        onClick={() => {
                                                                            handleEditToggle(
                                                                                `${x.first_name} ${x.last_name}`,
                                                                                x.username,
                                                                                x.id,
                                                                                x.active,
                                                                                x.per_day_limit,
                                                                                x.credit_balance
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            fontSize:
                                                                                '16px',
                                                                            color: 'blue',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                    <Modal
                                                                        isOpen={
                                                                            editShow
                                                                        }
                                                                        toggle={
                                                                            editToggle
                                                                        }
                                                                        backdrop="static">
                                                                        <ModalBody>
                                                                            <div className="text-left mb-4">
                                                                                <span className="font-bold">
                                                                                    {
                                                                                        fullName
                                                                                    }
                                                                                </span>
                                                                                <br></br>
                                                                                <span
                                                                                    className="text-muted "
                                                                                    style={{
                                                                                        fontSize:
                                                                                            '12px',
                                                                                    }}>
                                                                                    {
                                                                                        email
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <label>
                                                                                    Status
                                                                                </label>
                                                                                <Input
                                                                                    type="select"
                                                                                    value={
                                                                                        status
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setStatus(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }>
                                                                                    <option
                                                                                        value={
                                                                                            '1'
                                                                                        }>
                                                                                        Active
                                                                                    </option>
                                                                                    <option
                                                                                        value={
                                                                                            '2'
                                                                                        }>
                                                                                        Inactive
                                                                                    </option>
                                                                                </Input>
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <label>
                                                                                    Per
                                                                                    Day
                                                                                    Limit
                                                                                </label>
                                                                                <Input
                                                                                    type="number"
                                                                                    min="0"
                                                                                    step="1"
                                                                                    value={
                                                                                        perDayLimit
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setPerDayLimit(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    placeholder="Enter Per Day Limit"
                                                                                />
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <label>
                                                                                    Credit
                                                                                    Balance
                                                                                </label>
                                                                                <Input
                                                                                    type="number"
                                                                                    min="0"
                                                                                    step="1"
                                                                                    value={
                                                                                        creditBalance
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setCreditBalance(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    placeholder="Enter Credit Balance"
                                                                                />
                                                                            </div>

                                                                            <div>
                                                                                <Button
                                                                                    className="me-2"
                                                                                    color="secondary"
                                                                                    onClick={
                                                                                        editToggle
                                                                                    }>
                                                                                    Close
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        handleSaveClose();
                                                                                    }}>
                                                                                    Save
                                                                                </Button>
                                                                            </div>
                                                                        </ModalBody>
                                                                    </Modal>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {pages > 1 && (
                                    <div
                                        className="d-flex flex-row justify-content-center align-items-center mt"
                                        size="sm">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            previousLabel="Previous"
                                            forcePage={page}
                                            onPageChange={(e) => {
                                                setLoading(true);
                                                setPage(e.selected);

                                                getUsers({
                                                    page: e.selected + 1,
                                                    filteredUser: filteredUser,
                                                });
                                            }}
                                            pageCount={pages}
                                            marginPagesDisplayed={2}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            breakClassName="break"
                                            size="sm"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <LoadingOverlay />
                    )}

                    {/* {children} */}
                </div>
            </div>
        </div>
    );
}
