import React, { useState, useEffect, useContext } from 'react';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { AuthContext } from '../../utils/AuthContext';
import '../../assets/css/table.css';
import { data } from 'jquery';
import ReactPaginate from 'react-paginate';
import '../../assets/css/pagination.css';
import 'bootstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle, FaLock, FaCopy } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from './LoadingOverlay';
export default function Data() {
    const {
        getData,
        dataList,
        pages,
        completed,
        pending,
        trigger,
        fetchError,
        setFetchError,
        fetchMsg,
        setFetchMsg,
        complete,
        clickLoading,
        filteredData,
        setFilteredData,
        counts,
        downloadCSV,
        toastrError,
        toastrMsg,
        setToastrError,
        setToastrMsg,
    } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (fetchError) {
            setLoading(false);
            toast.error(fetchError);
            setFetchError(null);
        }
        if (fetchMsg) {
            setLoading(false);
            toast.success(fetchMsg);
            setFetchMsg(null);
            getData({
                page: page + 1,
                startDate: convertDateFormat(startDate),
                endDate: convertDateFormat(endDate),
            });
        }
    }, [fetchError, fetchMsg]);

    useEffect(() => {
        if (toastrError) {
            setLoading(false);
            toast.error(toastrError);
            setToastrError(null);
            // setLoading(false);
        }
        if (toastrMsg) {
            setLoading(false);
            toast.success(toastrMsg);
            setToastrMsg(null);
            // setLoading(false);
        }
    }, [toastrError, toastrMsg]);

    useEffect(() => {
        if (dataList) {
            setLoading(false);
            // setPage(0);
        }
    }, [dataList]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.info('Copied to clipboard');
    };

    function convertDateFormat(inputDate) {
        return inputDate;
    }

    useEffect(() => {}, []);

    const convertToCSV = async (dataList, excludeColumns = []) => {
        // await getData({ page: -1 });

        if (!dataList || dataList.length === 0) return '';

        const stringifyHeaders = ['company_employee_growth', 'all_positions'];

        const escapeQuotes = (value) => {
            const strValue = String(value); // Ensure value is a string
            return strValue.replace(/"/g, '""');
        };

        // Get the headers from the keys of the first object
        const headers = Object.keys(dataList[0]).filter(
            (header) => !excludeColumns.includes(header)
        );

        // Create CSV rows
        // const rows = dataList.map((item) =>
        //     headers.map((header) => `"${item[header] || ''}"`).join(',')
        // );

        const rows = dataList.map((item) =>
            headers
                .map((header) => {
                    const value = item[header];
                    let cellValue;
                    if (stringifyHeaders.includes(header) && value) {
                        cellValue = JSON.stringify(value);
                    } else {
                        cellValue = value || '';
                    }
                    // Escape quotes and enclose in double quotes
                    return `"${escapeQuotes(cellValue)}"`;
                })
                .join(',')
        );

        // Combine headers and rows into a single CSV string
        return [headers.join(','), ...rows].join('\n');
    };

    function formatTimestamp(isoString) {
        const date = new Date(isoString);

        // Define options for date and time formatting
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        // Format date using options
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date);
    }

    function formatStartedOn(isoString) {
        const date = new Date(isoString);

        // Define options for date and time formatting
        const options = {
            year: 'numeric',
            month: 'short',
        };

        // Format date using options
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date);
    }

    const handleCSVDownload = () => {
        setLoading(true);
        downloadCSV(startDate, endDate);
        // console.log(response);
        // setLoading(false);
    };

    return (
        <div className="fix-header">
            <div id="wrapper">
                <Header />
                {/* <Sidebar /> */}
                <ToastContainer style={{ marginTop: '60px' }} />
                <div id="page-wrapper0">
                    <div className="row" style={{ marginTop: '60px' }}>
                        <div className="col-sm-12">
                            <div className="white-box mb-0">
                                <div
                                    style={{
                                        height: '40px',
                                    }}>
                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                        <div
                                            className="me-2 d-flex align-items-center"
                                            style={{ height: '100%' }}>
                                            <label className="me-2">
                                                From:
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control h-75"
                                                value={startDate}
                                                onChange={(e) =>
                                                    setStartDate(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div
                                            className="me-2 d-flex align-items-center"
                                            style={{ height: '100%' }}>
                                            <label className="me-2">To:</label>
                                            <input
                                                type="date"
                                                className="form-control h-75"
                                                value={endDate}
                                                onChange={(e) =>
                                                    setEndDate(e.target.value)
                                                }
                                            />
                                        </div>
                                        <button
                                            className="btn btn-info btn-sm"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoading(true);
                                                getData({
                                                    startDate:
                                                        convertDateFormat(
                                                            startDate
                                                        ),
                                                    endDate:
                                                        convertDateFormat(
                                                            endDate
                                                        ),
                                                });
                                                setPage(0);
                                            }}>
                                            Filter
                                        </button>
                                        <button
                                            className="btn btn-outline-info ms-2 btn-sm"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoading(true);
                                                setEndDate('');
                                                setStartDate('');
                                                getData();
                                                setPage(0);
                                            }}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="row mb-0 pb-0"
                                    style={{ height: '40px' }}>
                                    <div className="d-flex justify-content-center align-items-center mb-3">
                                        {pending > 0 && (
                                            <div>
                                                <span
                                                    className="badge badge-success"
                                                    style={{
                                                        fontSize: '18px',
                                                        fontWeight: 'normal',
                                                        textTransform: 'none',
                                                    }}>
                                                    Completed: {completed}
                                                </span>
                                                <span
                                                    className="badge badge-danger ms-2"
                                                    style={{
                                                        fontSize: '18px',
                                                        fontWeight: 'normal',
                                                        textTransform: 'none',
                                                    }}>
                                                    Pending: {pending}
                                                </span>
                                                {pending > 0 && (
                                                    <span
                                                        className="badge btn btn-primary ms-2"
                                                        style={{
                                                            fontSize: '18px',
                                                            minWidth: '4rem',
                                                        }}
                                                        onClick={() => {
                                                            if (!clickLoading) {
                                                                complete();
                                                            }
                                                        }}>
                                                        {clickLoading ? (
                                                            <div
                                                                className="spinner-border text-light ms-2"
                                                                role="status"
                                                                style={{
                                                                    width: '1.2rem',
                                                                    height: '1.2rem',
                                                                    textAlign:
                                                                        'center',
                                                                }}></div>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        'normal',
                                                                    textTransform:
                                                                        'none',
                                                                }}>
                                                                Fetch Pending{' '}
                                                            </span>
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {dataList && dataList.length > 0 && (
                                            <div
                                                className="col d-flex justify-content-end align-items-center"
                                                style={{
                                                    height: '100%',
                                                }}>
                                                {counts?.c > 0 && (
                                                    <div className="me-2">
                                                        {counts?.a} -{' '}
                                                        {counts?.b} of{' '}
                                                        {counts?.c}
                                                    </div>
                                                )}
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        minWidth: '110px',
                                                    }}
                                                    disabled={loading}
                                                    onClick={() => {
                                                        handleCSVDownload();
                                                    }}>
                                                    {loading ? (
                                                        <div
                                                            className="spinner-border text-primary"
                                                            role="status"
                                                            style={{
                                                                width: '1rem',
                                                                height: '1rem',
                                                                textAlign:
                                                                    'center',
                                                            }}></div>
                                                    ) : (
                                                        'Download CSV'
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: 'calc(100vh - 240px)',
                                    }}>
                                    <div
                                        // className="table-responsive"
                                        style={{
                                            height: '100%',

                                            overflow: 'scroll',
                                        }}>
                                        <table
                                            className="table table-striped"
                                            style={{
                                                fontSize: '15px',
                                                // width: '100%',
                                                // tableLayout: 'fixed', // Ensures that the table layout is fixed
                                                // borderCollapse: 'collapse',
                                            }}>
                                            <thead
                                                className="thead-sticky table-info"
                                                style={{
                                                    maxWidth: '200px',
                                                    minWidth: '200px',
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'normal',
                                                }}>
                                                <tr>
                                                    <th
                                                        style={{
                                                            maxWidth: '50px',
                                                            minWidth: '50px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        #
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '150px',
                                                            minWidth: '150px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Status
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Timestamp
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        First Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            // width: '300px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Last Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '300px',
                                                            minWidth: '300px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Full Name
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '100px',
                                                            minWidth: '100px',
                                                        }}>
                                                        Headline
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '100px',
                                                            minWidth: '100px',
                                                            // wordWrap: 'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Summary
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '130px',
                                                            minWidth: '130px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Connections
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Linkedin URL
                                                    </th>

                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Current Company
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Current Title
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '150px',
                                                            minWidth: '150px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Started On
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Location
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '150px',
                                                            minWidth: '150px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        All Positions
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Description
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Industry
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Type
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Specialties
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Linkedin URL
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '200px',
                                                            minWidth: '200px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Employee Growth
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Website
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Headquaters
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '370px',
                                                            minWidth: '370px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Estimated
                                                        Revenue
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '410px',
                                                            minWidth: '410px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Location
                                                    </th>
                                                    <th
                                                        style={{
                                                            maxWidth: '240px',
                                                            minWidth: '240px',
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}>
                                                        Company Employee Count
                                                    </th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataList &&
                                                    dataList.map((x, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {page * 25 +
                                                                    (i + 1)}
                                                            </td>
                                                            <td>
                                                                {x.is_complete ? (
                                                                    <span
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="bottom"
                                                                        className="text-success font-bold">
                                                                        Completed
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="bottom"
                                                                        className="text-danger font-bold">
                                                                        Pending
                                                                    </span>
                                                                )}{' '}
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '10px',
                                                                    }}>
                                                                    <i>
                                                                        {x.is_locked && (
                                                                            <FaLock
                                                                                style={{
                                                                                    fontSize:
                                                                                        '8px',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </i>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {formatTimestamp(
                                                                    x.created_at
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.first_name &&
                                                                x.first_name
                                                                    .length >
                                                                    25 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.first_name
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-fname"
                                                                            data-tooltip-content={
                                                                                x.first_name
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.first_name.slice(
                                                                                0,
                                                                                25
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-fname"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.first_name
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.last_name &&
                                                                x.last_name
                                                                    .length >
                                                                    25 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.last_name
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-lname"
                                                                            data-tooltip-content={
                                                                                x.last_name
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.last_name.slice(
                                                                                0,
                                                                                25
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-lname"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.last_name
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.full_name &&
                                                                x.full_name
                                                                    .length >
                                                                    25 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.full_name
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-fullname"
                                                                            data-tooltip-content={
                                                                                x.full_name
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.full_name.slice(
                                                                                0,
                                                                                25
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-fullname"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.full_name
                                                                )}
                                                            </td>

                                                            <td>
                                                                {x.headline && (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.headline
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-head"
                                                                            data-tooltip-content={
                                                                                x.headline
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            <FaCopy
                                                                                style={{
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    color: 'lightblue',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-head"
                                                                        />
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {x.about && (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.about
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-about"
                                                                            data-tooltip-content={
                                                                                x.about
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            <FaCopy
                                                                                style={{
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    color: 'lightblue',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-about"
                                                                        />
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {x.connections}
                                                            </td>

                                                            <td>
                                                                <a
                                                                    href={
                                                                        x.linkedin_url
                                                                    }
                                                                    target="_blank"
                                                                    className="text-decoration-none">
                                                                    {x.linkedin_url &&
                                                                    x
                                                                        .linkedin_url
                                                                        .length >
                                                                        50
                                                                        ? x.linkedin_url.slice(
                                                                              0,
                                                                              25
                                                                          ) +
                                                                          '...' +
                                                                          x.linkedin_url.slice(
                                                                              -22
                                                                          )
                                                                        : x.linkedin_url}
                                                                </a>
                                                            </td>

                                                            <td>
                                                                {x.current_company_name &&
                                                                x
                                                                    .current_company_name
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.current_company_name
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-cname"
                                                                            data-tooltip-content={
                                                                                x.current_company_name
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.current_company_name.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-cname"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.current_company_name
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.current_title &&
                                                                x.current_title
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.current_title
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-ctitle"
                                                                            data-tooltip-content={
                                                                                x.current_title
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.current_title.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-ctitle"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.current_title
                                                                )}
                                                            </td>
                                                            <td>
                                                                {formatStartedOn(
                                                                    x.current_company_started_on
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.geo_region &&
                                                                x.geo_region
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.geo_region
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-geo"
                                                                            data-tooltip-content={
                                                                                x.geo_region
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.geo_region.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-geo"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.geo_region
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.all_positions && (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    JSON.stringify(
                                                                                        x.all_positions
                                                                                    )
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-pos"
                                                                            data-tooltip-content={JSON.stringify(
                                                                                x.all_positions
                                                                            )}
                                                                            data-tooltip-place="bottom">
                                                                            <FaCopy
                                                                                style={{
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    color: 'lightblue',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                maxWidth:
                                                                                    '600px',
                                                                                wordWrap:
                                                                                    'break-word',
                                                                                whiteSpace:
                                                                                    'normal',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-pos"
                                                                        />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.company_description && (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.company_description
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip_desc"
                                                                            data-tooltip-content={
                                                                                x.company_description
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            <FaCopy
                                                                                style={{
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    color: 'lightblue',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip_desc"
                                                                        />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        '300px',
                                                                    minWidth:
                                                                        '300px',
                                                                    wordWrap:
                                                                        'break-word',
                                                                    whiteSpace:
                                                                        'normal',
                                                                }}>
                                                                {x.company_industry &&
                                                                x
                                                                    .company_industry
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.company_industry
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-ind"
                                                                            data-tooltip-content={
                                                                                x.company_industry
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x
                                                                                .company_industry
                                                                                .length >
                                                                            50
                                                                                ? x.company_industry.slice(
                                                                                      0,
                                                                                      50
                                                                                  ) +
                                                                                  '...'
                                                                                : x.company_industry}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                maxWidth:
                                                                                    '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-ind"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.company_industry
                                                                )}
                                                            </td>
                                                            <td>
                                                                {x.company_type}
                                                            </td>
                                                            <td>
                                                                {x.company_specialties &&
                                                                x
                                                                    .company_specialties
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.company_specialties
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-spec"
                                                                            data-tooltip-content={
                                                                                x.company_specialties
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.company_specialties.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-spec"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.company_specialties
                                                                )}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={
                                                                        x.company_linkedin_url
                                                                    }
                                                                    target="_blank"
                                                                    className="text-decoration-none">
                                                                    {x.company_linkedin_url &&
                                                                    x
                                                                        .company_linkedin_url
                                                                        .length >
                                                                        50
                                                                        ? x.company_linkedin_url.slice(
                                                                              0,
                                                                              25
                                                                          ) +
                                                                          '...' +
                                                                          x.company_linkedin_url.slice(
                                                                              -22
                                                                          )
                                                                        : x.company_linkedin_url}
                                                                </a>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        '300px',
                                                                    minWidth:
                                                                        '300px',
                                                                    wordWrap:
                                                                        'break-word',
                                                                    whiteSpace:
                                                                        'normal',
                                                                }}>
                                                                {x.company_employee_growth && (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    JSON.stringify(
                                                                                        x.company_employee_growth
                                                                                    )
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip_growth"
                                                                            data-tooltip-content={JSON.stringify(
                                                                                x.company_employee_growth
                                                                            )}
                                                                            data-tooltip-place="bottom">
                                                                            <FaCopy
                                                                                style={{
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    color: 'lightblue',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip_growth"
                                                                        />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={
                                                                        x.company_website
                                                                    }
                                                                    target="_blank"
                                                                    className="text-decoration-none">
                                                                    {x.company_website &&
                                                                    x
                                                                        .company_website
                                                                        .length >
                                                                        50
                                                                        ? x.company_website.slice(
                                                                              0,
                                                                              25
                                                                          ) +
                                                                          '...' +
                                                                          x.company_website.slice(
                                                                              -22
                                                                          )
                                                                        : x.company_website}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {x.company_hq &&
                                                                x.company_hq
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.company_hq
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-hq"
                                                                            data-tooltip-content={
                                                                                x.company_hq
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.company_hq.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-hq"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.company_hq
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    x.company_estimated_revenue
                                                                }
                                                            </td>
                                                            <td>
                                                                {x.company_location &&
                                                                x
                                                                    .company_location
                                                                    .length >
                                                                    50 ? (
                                                                    <>
                                                                        <a
                                                                            style={{
                                                                                alignSelf:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                copyToClipboard(
                                                                                    x.company_location
                                                                                );
                                                                            }}
                                                                            data-tooltip-id="my-tooltip-comp-loc"
                                                                            data-tooltip-content={
                                                                                x.company_location
                                                                            }
                                                                            data-tooltip-place="bottom">
                                                                            {x.company_location.slice(
                                                                                0,
                                                                                50
                                                                            ) +
                                                                                '...'}
                                                                        </a>
                                                                        <Tooltip
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                width: '400px',
                                                                                overflow:
                                                                                    'auto',
                                                                                zIndex: '100',
                                                                            }}
                                                                            id="my-tooltip-comp-loc"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    x.company_location
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    x.company_employee_count
                                                                }
                                                            </td>
                                                            {/* <td>
                                                                <button
                                                                    className="btn btn-sm btn-primary"
                                                                    onClick={() => {
                                                                        setLoading(
                                                                            true
                                                                        );
                                                                        trigger(
                                                                            x.id
                                                                        );
                                                                    }}>
                                                                    Click
                                                                </button>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {pages > 1 && (
                                <div
                                    className="d-flex flex-row justify-content-center align-items-center mt"
                                    size="sm">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="Next"
                                        previousLabel="Previous"
                                        forcePage={page}
                                        onPageChange={(e) => {
                                            setLoading(true);
                                            setPage(e.selected);

                                            getData({
                                                page: e.selected + 1,
                                                startDate:
                                                    convertDateFormat(
                                                        startDate
                                                    ),
                                                endDate:
                                                    convertDateFormat(endDate),
                                            });
                                        }}
                                        pageCount={pages}
                                        marginPagesDisplayed={2}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        breakClassName="break"
                                        size="sm"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* {children} */}
                </div>
            </div>
        </div>
    );
}
