import React, { useContext, useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../utils/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    const {
        login,
        token,
        toastrError,
        toastrMsg,
        setToastrError,
        setToastrMsg,
    } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/data');
        }
    }, [token]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        }
        if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
    }, [toastrError, toastrMsg]);

    function handleOnSuccess(response) {
        const idToken = response?.credential;

        if (idToken) {
            const decodeToken = jwtDecode(idToken);
            const email = decodeToken?.email;
            const firstName = decodeToken?.given_name;
            const lastName = decodeToken?.family_name;

            login(email, firstName, lastName);
        }
    }

    function handleOnFailure() {}
    return (
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <ToastContainer />
            <div
                className="card p-4 shadow-sm"
                style={{ maxWidth: '400px', width: '100%' }}>
                <div className="card-body text-center">
                    <h2 className="card-title mb-4">Login</h2>
                    <div className="text-center w-100">
                        <GoogleOAuthProvider
                            className="w-100"
                            clientId="574311921374-hiau7rcmmat9h3eq40r756iihcifiob5.apps.googleusercontent.com">
                            <GoogleLogin
                                // href=""
                                theme="filled_blue"
                                className="btn  my-4 w-100"
                                onSuccess={handleOnSuccess}
                                onError={handleOnFailure}
                                // useOneTap
                                autoSelect={false}
                            />
                        </GoogleOAuthProvider>
                    </div>

                    {/* <div
                        id="g_id_onload"
                        data-client_id="199752190239-jdpt7nhh7tjfudishnf8p73eno3dv2ca.apps.googleusercontent.com"
                        data-login_uri="http://localhost:8080/login/"
                        data-callback="handleCredentialResponse"
                        data-auto_select="true"
                        data-itp_support="true"></div>
                    <div className="g_id_signin" data-type="standard"></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="card mt-5">
                                    <div className="card-body text-center">
                                        <h2 className="card-title">Sign In</h2>
                                        <a
                                            href="{% provider_login_url 'google' %}"
                                            className="btn btn-danger btn-block">
                                            Sign in with Google
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
