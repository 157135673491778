import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './config';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loggedOut, setLoggedOut] = useState(false);
    const [toastrMsg, setToastrMsg] = useState(null);
    const [toastrError, setToastrError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [dataList, setDataList] = useState(null);
    const [taskList, setTaskList] = useState(null);
    const [codeData, setCodeData] = useState(null);
    const [pages, setPages] = useState(null);
    const [pending, setPending] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [fetchError, setFetchError] = useState(null);
    const [fetchMsg, setFetchMsg] = useState(null);
    const [clickLoading, setClickLoading] = useState(false);
    const [counts, setCounts] = useState(null);
    const [usersList, setUsersList] = useState(null);

    async function setLocalStorage(data) {
        localStorage.setItem('userInfo', JSON.stringify(data));
    }

    const login = async (email, firstName, lastName) => {
        setLoggedOut(false);

        try {
            const response = await axios.post(
                `${BASE_URL}/api/login/`,

                {
                    email,
                    firstName,
                    lastName,
                }
            );
            const incomingData = response.data;
            if (incomingData.success) {
                setLoggedOut(false);
                // Update state with authentication details

                await setLocalStorage(incomingData);
                setToken(incomingData.token);
            } else {
                setToastrError(incomingData.msg);
                setToastrMsg(null);
                // Handle unsuccessful login
            }
        } catch (error) {}
    };

    const getData = ({
        page = null,
        startDate = null,
        endDate = null,
    } = {}) => {
        let params = {
            startDate: startDate,
            endDate: endDate,
        };
        if (page) {
            params['page'] = page;
        }
        axios
            .get(`${BASE_URL}/api/get-data/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;

                if (incomingData?.success) {
                    if (incomingData.filter) {
                        setFilteredData(incomingData?.data);
                    } else {
                        setDataList(incomingData?.data);
                        setCounts(incomingData?.counts);
                        setPages(incomingData.num_pages);
                        setCompleted(incomingData.completed);
                        setPending(incomingData.pending);
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const getUsers = ({ page = null, filteredUser = null } = {}) => {
        let params = {
            filteredUser,
        };
        if (page) {
            params['page'] = page;
        }
        axios
            .get(`${BASE_URL}/api/get-users/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;

                if (incomingData?.success) {
                    setUsersList(incomingData?.data);
                    setCounts(incomingData?.counts);
                    setPages(incomingData.num_pages);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError?.status === 401 ||
                    incomingError?.status === 400
                ) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const getCode = () => {
        axios
            .get(`${BASE_URL}/api/get-code/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData?.success) {
                    setCodeData({
                        data: incomingData?.data,
                        scrappedToday: incomingData?.scrapped_today,
                    });
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const downloadCSV = (startDate = null, endDate = null) => {
        let params = {};
        if (startDate) {
            params['startDate'] = startDate;
        }
        if (endDate) {
            params['endDate'] = endDate;
        }
        axios
            .post(
                `${BASE_URL}/api/download-csv/`,
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    const csvBlob = new Blob([incomingData.csv_data], {
                        type: 'text/csv',
                    });
                    const url = window.URL.createObjectURL(csvBlob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'data.csv';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    setToastrMsg('Downloaded Successfully!');
                    setToastrError(null);
                } else {
                    setToastrError('Couldn;t downlaod CSV');
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError?.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const editUserDetails = (id, status, limit, credits, page) => {
        axios
            .post(
                `${BASE_URL}/api/edit-user-details/`,
                { id, status, limit, credits },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;

                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                    getUsers({ page });
                } else {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const complete = () => {
        setClickLoading(true);
        axios
            .get(`${BASE_URL}/api/complete/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                setClickLoading(false);
                if (incomingData?.success) {
                    setFetchError(null);
                    setFetchMsg(incomingData.msg);
                    // getData();
                } else {
                    if (incomingData?.show) {
                        setFetchMsg(null);
                        setFetchError(incomingData.msg);
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const completeUserPendingData = (id) => {
        setClickLoading(true);
        axios
            .post(
                `${BASE_URL}/api/complete/`,
                { id },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;
                setClickLoading(false);
                if (incomingData?.success) {
                    setFetchError(null);
                    setFetchMsg(incomingData.msg);
                    // getData();
                } else {
                    if (incomingData?.show) {
                        setFetchMsg(null);
                        setFetchError(incomingData.msg);
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const trigger = (id) => {
        axios
            .post(
                `${BASE_URL}/api/manual-trigger/`,
                { id },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;

                if (incomingData?.success) {
                    setFetchError(null);
                    setFetchMsg(incomingData.msg);
                    // getData();
                } else {
                    if (incomingData?.show) {
                        setFetchMsg(null);
                        setFetchError(incomingData.msg);
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const changePage = (pages) => {
        axios
            .post(
                `${BASE_URL}/api/change-page/`,
                { pages },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;
                if (incomingData?.success) {
                    getCode();
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (incomingError.status === 401) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    async function removeLocalStorage() {
        localStorage.removeItem('userInfo');
        setToken(null);
        setLoggedOut(true);
    }

    const logout = async () => {
        localStorage.removeItem('userInfo');
        await removeLocalStorage();
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                loggedOut,
                dataList,
                taskList,
                codeData,
                pages,
                completed,
                usersList,
                pending,
                fetchError,
                fetchMsg,
                clickLoading,
                counts,
                toastrMsg,
                completeUserPendingData,
                downloadCSV,
                setToastrMsg,
                toastrError,
                setToastrError,
                editUserDetails,
                getUsers,
                complete,
                setFetchMsg,
                setFetchError,
                trigger,
                changePage,
                getCode,
                getData,
                setToken,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
