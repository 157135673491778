import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export const AuthenticatedRoute = ({ children }) => {
    const [token, setToken] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.token : null;
    });

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setToken(userInfo.token);
        }
    }, []); // Empty dependency array to run only once

    return token ? children : <Navigate to="/login" />;
};

export const PublicRoute = ({ children }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setToken(userInfo.token);
        }
    }, []);

    return token ? <Navigate to="/data" /> : children;
};

export const AdminRoute = ({ children }) => {
    const [token, setToken] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.token : null;
    });
    const [isAdmin, setIsAdmin] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.admin : null;
    });

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setToken(userInfo.token);
            setIsAdmin(userInfo.admin);
        }
    }, []);

    return token && isAdmin ? children : <Navigate to="/login" />;
};
