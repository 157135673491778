import logo from './logo.svg';
import './App.css';

// import './assets/bootstrap/dist/css/bootstrap.min.css';
// import './assets/plugins/bower_components/sidebar-nav/dist/sidebar-nav.min.css';
// import './assets/plugins/bower_components/morrisjs/morris.css';
// import './assets/plugins/bower_components/chartist-js/dist/chartist.min.css';
// import './assets/plugins/bower_components/chartist-plugin-tooltip-master/dist/chartist-plugin-tooltip.css';
// import './assets/css/animate.css';
// import './assets/css/style.css';
// import './assets/css/colors/default.css';

import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Content from './components/Content.js';

import { BrowserRouter, Route, Switch, Routes } from 'react-router-dom';
import { AuthProvider } from './utils/AuthContext.js';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Content />
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
