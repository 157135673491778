import React from 'react';
import '../assets/css/style.css';
import '../assets/css/animate.css';
import '../assets/css/colors/default.css';
import '../assets/plugins/bower_components/sidebar-nav/dist/sidebar-nav.min.css';

import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Data from './screens/Data';
import Settings from './screens/Settings';
import Home from './screens/Home';
import Login from './creds/Login';
import Admin from './screens/Admin';
import {
    PublicRoute,
    AuthenticatedRoute,
    AdminRoute,
} from '../routes/UrlRoutes';

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Routes,
} from 'react-router-dom';

function Content() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
                exact
                path="/admin"
                element={
                    <AdminRoute>
                        <Admin />
                    </AdminRoute>
                }
            />
            <Route
                exact
                path="/data"
                element={
                    <AuthenticatedRoute>
                        <Data />
                    </AuthenticatedRoute>
                }
            />
            <Route
                exact
                path="/settings"
                element={
                    <AuthenticatedRoute>
                        <Settings />
                    </AuthenticatedRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            />
        </Routes>
    );
}

export default Content;
