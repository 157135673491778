import React from 'react';
// import { Spinner } from 'react-bootstrap'; // If using Bootstrap

const LoadingOverlay = () => {
    return (
        <div style={overlayStyle}>
            <div
                className="spinner-border spinner-border-sm"
                role="status"
                style={{
                    width: '3rem',
                    height: '3rem',
                }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

const overlayStyle = {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it is on top of other elements
};

export default LoadingOverlay;
